<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <filter-clients ref="FilterClients" @update="totalRecords = $event"/>
            </b-card>
        </b-col>
        <b-col>
            <b-card title="">
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <b-row>
                        <b-col cols="12" md="9"
                               class="d-flex align-items-center justify-content-start mb-1"
                        >
                            <label>Mostrar</label>
                            <v-select
                                v-model="pageLength"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="['3','5','10','20', '50']"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                                @input="changeLength"
                            />
                            <create-client-button/>
                        </b-col>
                    </b-row>
                    <table-clients ref="tableClients"/>
                    <div class="mx-2 mb-2">
                        <b-row>
                            <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                            >
                                <span class="text-muted">Mostrant {{ pageLength }} de {{
                                        totalRecords
                                    }} clients</span>
                            </b-col>

                            <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                            >
                                <b-pagination
                                    :value="1"
                                    :total-rows="totalRecords"
                                    :per-page="pageLength"
                                    first-number
                                    last-number
                                    align="right"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                    class="mt-1 mb-0"
                                    @input="changePage"
                                >
                                    <template #prev-text>
                                        <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                        />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BRow,
    BCol,
    BTable,
    BCard,
    BBadge,
    BButton,
    BTooltip,
    BPagination,
    BFormInput,
    BOverlay,
    BFormGroup,
    BIcon,
    BInputGroupText,
} from 'bootstrap-vue'
import vSelect from "vue-select"
import TableClients from "@/views/clients/list/components/client/tableClients"
import CreateClientButton from "@/views/clients/list/components/client/createClientButton"
import {createHelpers} from "vuex-map-fields"
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import flatPickr from "vue-flatpickr-component"
import FilterClients from "@/views/clients/list/components/client/filterClientsView"

const {mapFields} = createHelpers({
    getterType: 'clients/getField',
    mutationType: 'clients/updateField',
})

export default {
    name: "ListClients",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BBadge,
        BButton,
        BTooltip,
        BPagination,
        BFormInput,
        BOverlay,
        BFormGroup,
        BIcon,
        BInputGroupText,

        vSelect,
        flatPickr,

        TableClients,
        CreateClientButton,
        FilterClients

    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: '',
            showLoading: false,

            CatalanLocale
        }
    },
    mounted() {
        this.$refs.FilterClients.getClients()
    },
    methods: {
        changePage(page) {
            this.page = page
            this.$refs.FilterClients.page = page
            this.$refs.FilterClients.getClients()
        },
        changeLength(length) {
            this.$refs.FilterClients.pageLength = length
            this.$refs.FilterClients.getClients()
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>