<template>
    <div>
        <b-row>
            <b-col cols="8">
                <div class="mb-2">
                    <h4>Filtre</h4>
                </div>
            </b-col>
            <b-col lg="4" md="4" class="text-right">
                <b-button variant="primary" @click="cleanFilters">
                    Netejar filtres
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4" >
                <b-form-group label="Nom" label-for="Nom">
                    <b-form-input v-model="searchFields.name" placeholder="Nom" autocomplete="off"
                                  @update="searchClients()"/>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group label="Telèfon" label-for="Telèfon">
                    <b-form-input v-model="searchFields.phone" placeholder="Telèfon" autocomplete="off"
                                  @update="searchClients()"/>
                </b-form-group>
            </b-col>
            <b-col cols="4" >
                <b-form-group label="NIF/CIF" label-for="NIF/CIF">
                    <b-form-input v-model="searchFields.nif_cif" placeholder="NIF/CIF" autocomplete="off"
                                  @update="searchClients()"/>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BTable,
    BCard,
    BBadge,
    BButton,
    BTooltip,
    BPagination,
    BFormInput,
    BOverlay,
    BFormGroup,
    BIcon,
    BInputGroupText,
} from 'bootstrap-vue'
import vSelect from "vue-select"
import TableClients from "@/views/clients/list/components/client/tableClients"
import {createHelpers} from "vuex-map-fields"
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import flatPickr from "vue-flatpickr-component"
const {mapFields} = createHelpers({
    getterType: 'client/getField',
    mutationType: 'client/updateField',
})

export default {
    name: "FilterClients",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BBadge,
        BButton,
        BTooltip,
        BPagination,
        BFormInput,
        BOverlay,
        BFormGroup,
        BIcon,
        BInputGroupText,

        vSelect,
        flatPickr,

        TableClients,
    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,
            showLoading: false,
            searchFields: [],
            searchConfig: [],

            CatalanLocale,
        }
    },
    watch: {
        totalRecords (currentValue) {
            this.$emit('update', currentValue);
        }
    },
    beforeMount() {
        this.$store.commit('client/RESET_STATE')
        this.$root.$on('callGetClients', () => {
            this.getClients()
        })
        this.getClients()
    },
    methods: {
        searchClients() {
            this.getClients()
        },
        cleanFilters() {
            this.searchFields = []
            this.getClients()
        },
        getClients() {
            this.showLoading = true
            this.searchConfig = [
                {
                    'type': 'concat_strings',
                    'concat_fields': ['name', 'first_surname', 'second_surname'],
                    'searchTerm': this.searchFields.name ?? ''
                },
                {'type': 'string', 'column': 'phone', 'searchTerm': this.searchFields.phone ?? ''},
                {'type': 'string', 'column': 'nif_cif', 'searchTerm': this.searchFields.nif_cif ?? ''},
            ]

            this.$store.dispatch('client/searchClients', {
                client_id: this.$route.params.id ?? '',
                per_page: this.pageLength,
                page: this.page,
                filter: this.searchConfig.length > 0 ? this.searchConfig : '',
            })
            .then(response => {
                this.showLoading = false
                this.totalRecords = response.total_records
                this.$emit('update',this.totalRecords)
            })
            .catch(error => {
                this.showLoading = false
                console.log(error)
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>