<template>
    <div>
        <b-table
            ref="refClientListTable"
            class="position-relative"
            :items="clients"
            responsive
            :fields="columns"
            primary-key="id"
            show-empty
            empty-text="No s'han trobat clients"
        >
            <template #cell(name)="data">
                <router-link :to="{ name: 'clients_edit', params: { id: data.item.id} }">
                    {{ data.item.name }} {{ data.item.first_surname }} {{ data.item.second_surname }}
                </router-link>
            </template>

            <template #cell(created_at)="data">
                {{ formatDate(data.item.created_at) }}
            </template>

            <template #cell(actions)="data">
                <div>
                    <router-link :to="{ name: 'clients_edit', params: { id: data.item.id} }">
                        <feather-icon
                            v-if="can('edit_clients')"
                            :id="`invoice-row-${data.item.id}-send-icon`"
                            icon="EditIcon"
                            class="cursor-pointer"
                            size="16"
                        />
                    </router-link>

                    <feather-icon
                        v-if="can('delete_clients')"
                        :id="`invoice-row-${data.item.id}-preview-icon`"
                        icon="TrashIcon"
                        size="16"
                        class="cursor-pointer mx-1 text-red"
                        @click="deleteClient(data.item.id)"
                    />
                    <b-tooltip
                        title="Eliminar client"
                        :target="`invoice-row-${data.item.id}-preview-icon`"
                    />
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import {BRow, BCol, BTable, BCard, BBadge, BButton, BTooltip, BPagination, BFormInput, BOverlay} from 'bootstrap-vue'
import vSelect from "vue-select"
import {mapState} from "vuex"
import Swal from "sweetalert2"
import {CLIENTS_CREATE} from "@/router/web/constants"

export default {
    name: "ListView",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BBadge,
        BButton,
        BTooltip,
        BPagination,
        BFormInput,
        BOverlay,

        vSelect,
    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,
            columns: [
                {key: 'name', label: 'Nom', sortable: false},
                {key: 'email', label: 'Email', sortable: false},
                {key: 'phone', label: 'Telèfon', sortable: false},
                {key: 'nif_cif', label: 'NIF/CIF', sortable: false},
                {key: 'created_at', label: 'Data de creació', sortable: false},
                {key: 'actions', label: 'Accions', sortable: false},
            ],
            searchFields: [],
            searchConfig: [],
            searchTerm: '',
            showLoading: false,
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                Llar: 'light-primary',
                CO: 'light-success',
                CET: 'light-info',
                null: 'light-warning',
            }

            return status => statusColor[status]
        },
        ...mapState('client', ['clients']),
    },
    created() {
        this.getClients()
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        formatDate(date) {
            const dateFormated = new Date(date)

            return dateFormated.toLocaleDateString("es-ES")
        },
        getClients() {
            this.showLoading = true

            this.searchConfig = [
                {
                    'type': 'concat_strings',
                    'concat_fields': ['clients.name', 'clients.first_surname', 'clients.second_surname'],
                    'searchTerm': this.searchFields.client ?? ''
                },
            ]

            this.$store.dispatch('client/searchClients', {
                per_page: this.pageLength,
                page: this.page,
                filter: this.searchConfig.length > 0 ? this.searchConfig : '',
            })
            .then(response => {
                this.showLoading = false
                this.totalRecords = response.total_records
            })
            .catch(error => {
                this.showLoading = false
                console.log(error)
            })
        },
        deleteClient(id) {
            Swal.fire({
                title: "Esteu segur d'esborrar client?",
                text: "Un cop esborrat no ho podràs recuperar!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Sí, esborra'l!",
                cancelButtonText: "Cancel·lar",
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('client/deleteClient', {id: id})
                    .then(response => {
                        this.showLoading = false
                        this.getClients()
                    })
                    .catch(error => {
                        this.showLoading = false
                        console.log(error)
                    })
                }
            })
        },
        changePage(page) {
            this.page = page
            this.getClients()
        },
        changeLength(length) {
            this.length = length
            this.getClients()
        },
        searchClient() {
            this.getClients()
        },
        goToCreate() {
            this.$router.push(CLIENTS_CREATE)
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>